import { Fragment } from "react";
import { Card } from '@shopify/polaris';
import logo from "../assets/logo.svg";
import check from "../assets/check.svg";
import close from "../assets/close.svg";
const TalkShopLive = ({data, isCountryUS}) => {

  // useEffect(() => {
  //   const handleModal = () => {
  //     if (ref.current && !ref.current.contain(event.target)) {
  //       alert("You clicked outside of me!");
  //       setEdit(false);
  //     }
  //   };
  // }, [ref]);
  return (
    <Fragment>
    <Card sectioned>
        <div className="flex alignCenter">
          <div className="flex w100 alignCenter">
            <div className="logoIcon bgSuccess">
              <img src={logo} alt="LalaLive" width="30" />
            </div>
            <h5 className="mb0 ml-1">LalaLive</h5>
          </div>
          <a
            href="#"
            className="textNowrap mlAuto textBlue"
          >
            Need Help?
          </a>
        </div>
        <p className="muteText mt1r">
          Your shop meets LalaLive basic guidelines
        </p>
        {data?.adminShop ? (
          <div className="require">
            <img src={isCountryUS ? check : close} alt="ok" width={14} />
            <p className="mb0">Store country is in {data?.adminShop?.country ? data?.adminShop?.country : "United States"}</p>
          </div>
        ) : null}
      </Card>
    </Fragment>
  );
};

export default TalkShopLive;
