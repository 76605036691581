import "./shop.css";
import {gql, useQuery} from '@apollo/client';
import {useEffect, useState} from 'react';

import {
  TalkShopLive,
  Account,
  LoadingState
} from './components';

const ONBOARDING_PAGE_QUERY = gql`
  query OnboardingPageQuery {
    adminShop {
      id
      country
      vendor_id
      deletedAt
      domain
    }
  }
`;

// import Terms from "./components/Terms";
const ShopifySetting = () => {
  
  const {data, loading} = useQuery(ONBOARDING_PAGE_QUERY);
  const [isCountryUS, setIsCountry] = useState(false)

  useEffect(() => {
    if (data?.adminShop?.country === "United States" || data?.adminShop?.country === "US") {
      setIsCountry(true)
    }
  }, [data]);

  if (loading) {
    return <LoadingState />;
  }
  return (
    <div className="mxAuto mt-2" style={{ maxWidth: "720px" }}>
      <h3>Welcome to LalaLive</h3>
      <p className="muteText">
        Let's get you set up so you can sell your products on LalaLive
      </p>
      <div className="grid3 mt-1">
        <div className="">
          <h5>App Requirements</h5>
          <p className="muteText">
            Complete this checklist before you connect your account to ensure
            that your shop meets LalaLive guidelines
          </p>
        </div>
        <div className="grid3Span2">
          <TalkShopLive data={data} isCountryUS={isCountryUS}/>
        </div>
      </div>
      <div className="separator" />
      <div className="grid3">
        <div className="">
          <h5>LalaLive Account</h5>
          <p className="muteText">
            Conect your LalaLive account so you can manage and sync with
            LalaLive
          </p>
        </div>
        <div className="grid3Span2">
          <Account queryData={data} isCountryUS={isCountryUS}/>
        </div>
      </div>
      {/* <div className="separator" />
      <div className="grid3">
        <div className="">
          <h5>Channel</h5>
        </div>
        <div className="grid3Span2">
          <Channel />
        </div>
      </div>
      <div className="separator" />
      <div className="grid3">
        <div className="">
          <h5>Fees & Payments</h5>
          <p className="muteText">
            Your LalaLive transaction fees and payment details
          </p>
        </div>
        <div className="grid3Span2">
          <Payments />
        </div>
      </div> */}
      {/* <div className="separator" />
      <div className="grid3">
        <div className="">
          <h5>Terms & Conditions</h5>
        </div>
        <div className="grid3Span2">
          <Terms />
        </div>
      </div> */}
    </div>
  );
};

export default ShopifySetting;