import React from 'react';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import {Onboarding} from '../sections';
import AppProvider from './AppProvider';
const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<AppProvider />}>
          <Route index element={<Onboarding />} />
          {/* <Route path="settings" element={<Settings />} /> */}
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default Router;
