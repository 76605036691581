import { useState, useCallback, useEffect } from "react";
import { Form, FormLayout, TextField, Button, Modal, TextContainer, Card, InlineError } from '@shopify/polaris';
// import { useAuthenticatedFetch } from "../../../hooks";
import { gql, useMutation, useQuery } from '@apollo/client';
import {useNavigate} from '@shopify/app-bridge-react';

const CONNECT_DISCONNET_SHOP_MUTATION = gql`
  mutation connectDisconnectShop($vendor_id: Int) {
    connectDisconnectShop(vendor_id: $vendor_id) {
      id
      connected
      vendor_id
    }
  }
`;
const USER_QUERY = gql`
  query UserQuery($id: Int!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
    }
  }
`;
const TalkShopLive = ({ queryData, isCountryUS}) => {

  const [active, setActive] = useState(false);
  const [connected, setConnected] = useState(false);
  const [email, setEmail] = useState('');
  const [user, setUser] = useState({ name: '', email: '' })
  const [password, setPassword] = useState('');
  const [authLoading, setAuthLoading] = useState(false);
  const [formError, setError] = useState({ username: '', password: '' })
  const [connectDisconnectShop, { loading }] = useMutation(
    CONNECT_DISCONNET_SHOP_MUTATION,
  );
  const navigate = useNavigate();
  let userData = null
  if (queryData?.adminShop?.vendor_id) {
    const { data } = useQuery(USER_QUERY, {
      variables: { id: queryData?.adminShop.vendor_id },
    });
    userData = data;
  }
  useEffect(() => {
    if (queryData?.adminShop.deletedAt === null && queryData?.adminShop.vendor_id !== null) {
      setConnected(true)
    }
    if (userData) setUser({ name: `${userData?.user.firstName} ${userData?.user.lastName}`, email: userData?.user.email })
  }, [queryData, userData])

  useEffect(() => {
    setError({ username: '', password: '' })
  }, [email, password])

  const handleChange = useCallback(() => setActive(!active), [active]);
  const activator = (<Button
    destructive={connected}
    disabled={!isCountryUS}
    primary={!connected}
    loading={loading}
    onClick={handleChange}
  >
    {connected ? 'Disconnect' : 'Connect'}
  </Button>)

  const handleSubmit = async () => {
    if (!isCountryUS) {
      return
    }
    if (email === '') {
      setError({username: 'Email is required'}) 
      return
    }
    if (password === '') {
      setError({password: 'Password is required'})
      return
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ username: email, password, store_domain: queryData?.adminShop?.domain})
    };
    try {
      setAuthLoading(true)
      let result = await fetch('https://api.lala.live/v1/auth/signin', requestOptions)
      if (result.ok) {
        const { data } = await result.json()
        const res = await connectDisconnectShop({ variables: { vendor_id: data.vendor.id } });
        setUser({ name: `${data?.vendor.user.firstName} ${data?.vendor.user.lastName}`, email: data.vendor.user.email })
        setConnected(res.data.connectDisconnectShop.connected)
        if(res.data.connectDisconnectShop.connected) {
          navigate("https://front.lala.live")
        }
      } else {
        const { errors } = await result.json()
        if (errors) {
          errors.map(err => setError({ [err.key]: err.description }))
        }
      }
      setAuthLoading(false)
    } catch (error) {
      setAuthLoading(false)
    }
  }

  const handleDisconnect = async () => {
    const res = await connectDisconnectShop();
    setConnected(res.data.connectDisconnectShop.connected)
    setUser({ name: '', email: '' })
  }
  const handleEmailChange = useCallback((value) => setEmail(value), []);
  const handlePasswordChange = useCallback((value) => setPassword(value), []);

  const suodoName = user.name && `${user.name.charAt(0).toUpperCase()}`
  return (
    <Card sectioned>
      <div className="flex">
        {connected ?
          <div className="flex w100 --p-space-0">
            <div className="logoIcon bgSuccess">
              {suodoName}
            </div>
            <div className="ml-1">
              <h5>{user.name}</h5>
              <p className="muteText mb50">
                Your account is
                <span className="textSuccess">
                  {" "}connected
                </span>
              </p>
            </div>
          </div>
          :
          <div className="flex w100">
            <div>
              <p className="muteText mb50">
                Your account is
                <span className="textDanger">
                  {" "}disconnected
                </span>
              </p>
            </div>
          </div>
        }
        {!connected ? (
          <Modal
            activator={activator}
            open={active}
            onClose={handleChange}
            title="Login"
          // primaryAction={{
          //   content: 'Connect',
          //   onAction: handleChange,
          // }}
          >
            <Modal.Section>
              <TextContainer>
                <Form noValidate onSubmit={handleSubmit}>
                  <FormLayout>
                    <TextField
                      onChange={handleEmailChange}
                      value={email}
                      label="Email"
                      type="email"
                      helpText={
                        <span>
                          Enter your email associated with lalalive account
                        </span>
                      }
                    />
                    {formError.username &&
                      <InlineError message={formError.username} fieldID="username" />
                    }
                    <TextField
                      onChange={handlePasswordChange}
                      value={password}
                      label="Password"
                      type="password"
                      autoComplete="password"
                    />
                    {formError.password &&
                      <InlineError message={formError.password} fieldID="password" />
                    }
                    <Button submit loading={authLoading}>Submit</Button>
                  </FormLayout>
                </Form>
              </TextContainer>
            </Modal.Section>
          </Modal>
        )
          :
          <Button onClick={handleDisconnect}
            destructive={connected}
            loading={loading}
            primary={!connected}>
            {connected ? 'Disconnect' : 'Connect'}
          </Button>
        }
      </div>
      {connected &&
        <p className="muteText mb0">
          Account Email: <span className="textDark fw600">{user.email}</span>
        </p>
      }

    </Card>
  );
};

export default TalkShopLive;
